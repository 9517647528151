import React from "react"
import _ from "lodash"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../../components/Layout/Layout"
import Template from "../../components/Template/Template"
import { renderPageNode } from "../../utilities/renderPageNode"
import { renderTags } from "../../utilities/renderTags"
import SEO from "../../components/seo"
import {
  doesTagsContainNoIndex,
  getHrefLangTagProps
} from "../../utilities/utilities"
import getPageData from "../../analytics/getPageData"
import { useScrollTracking } from "../../analytics/useScrollTracking"
import { usePageTracking } from "../../analytics/usePageTracking"

export const query = graphql`
  query ($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...pageFields
    }
    site {
      siteMetadata {
        defaultLocale
        siteUrl
      }
    }
  }
`

const Page = props => {
  useScrollTracking()
  usePageTracking()

  const { localeLinks, locale, defaultLocale } = props.pageContext
  const options = {
    renderNode: renderPageNode(locale, props.pageContext.defaultLocale)
  }
  const page = props.data.contentfulPage

  function renderBody(props, options) {
    if (page.pageBody) {
      return renderRichText(page.pageBody, options)
    }

    return null
  }

  function renderHrefLangTags() {
    return getHrefLangTagProps(
      localeLinks,
      props.data.site.siteMetadata.siteUrl
    ).map(tagProps => {
      return <link {...tagProps} key={tagProps.key} />
    })
  }

  const pageStartScriptTags = renderTags(
    locale,
    defaultLocale,
    page.bodyStartScripts
  )
  const pageEndScriptTags = renderTags(
    locale,
    defaultLocale,
    page.bodyEndScripts
  )
  const hasFixedMenu = _.defaultTo(_.get(page, "fixedMenu"), true)

  const htmlLang = locale.split("-")[0]
  const title = _.get(page, "title", "")
  const metaDescription = _.get(page, "description.description", "")
  const canonicalUrl = _.get(page, "canonicalUrl", "")

  const pageUrl = `${props.data.site.siteMetadata.siteUrl}${_.get(
    props,
    "pageContext.pageUrl",
    ""
  )}`

  const pageData = getPageData(
    locale,
    {
      pagePerimeter: _.get(page, "pagePerimeter", ""),
      pagePublication: page.segmentUpdatedAt,
      pageFeature: _.get(page, "pageFeature", "")
    },
    page.slug,
    props.path
  )
  const doesPageContainsNoIndexTag =
    (page.template &&
      page.template.headTags &&
      doesTagsContainNoIndex(page.template.headTags))

  return (
    <Layout
      locale={props.pageContext.locale}
      defaultLocale={props.pageContext.defaultLocale}
    >
      <SEO
        description={metaDescription}
        lang={htmlLang}
        title={title}
        url={pageUrl}
        socialImage={page.template?.socialMediaImage}
        includeSelfCanonical={!doesPageContainsNoIndexTag}
      />
      <Helmet>
        <script type="text/javascript">{`${pageData}`}</script>
        {page.template &&
          page.template.headTags &&
          renderTags(
            locale,
            props.pageContext.defaultLocale,
            page.template.headTags
          )}
        {renderHrefLangTags()}
        {canonicalUrl && (
          <link rel="canonical" href={`https://agicap.com${canonicalUrl}`} />
        )}
      </Helmet>
      <Template
        {...page.template}
        isMenuFixed={hasFixedMenu}
        localeLinks={localeLinks}
        locale={props.pageContext.locale}
        defaultLocale={props.pageContext.defaultLocale}
      >
        {pageStartScriptTags}
        <div className="page">{renderBody(props, options)}</div>
        {pageEndScriptTags}
      </Template>
    </Layout>
  )
}

Page.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export default Page
